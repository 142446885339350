<template>
    <div>
        <div class="drag-box" v-drag id="drag" v-if="isShowDrag">
            <Button type="primary" @click.stop="isShowDrag = false">关闭</Button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShowDrag: true,
        }
    },
    //自定义指令
    directives: {
        drag: {
            // 指令的定义
            bind: function (el) {
                let oDiv = el;  // 获取当前元素
                oDiv.onmousedown = (e) => {
                    // 算出鼠标相对元素的位置
                    let disX = e.clientX - oDiv.offsetLeft;
                    let disY = e.clientY - oDiv.offsetTop;

                    document.onmousemove = (e) => {
                        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                        let left = e.clientX - disX;
                        let top = e.clientY - disY;

                        oDiv.style.left = left + 'px';
                        oDiv.style.top = top + 'px';

                    };

                    document.onmouseup = (e) => {
                        document.onmousemove = null;
                        document.onmouseup = null;
                    }
                }
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.drag-box {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 100px;
    height: 100px;
    background: red;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    transition: 0.03s;
}
</style>